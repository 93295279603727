#nav1 {
    @media all and (min-width: $viewport-medium) {
        overflow:auto;

        a {
            color: $color-nav1;
            display: block;
            float: left;
            margin-right: $size-gutter;
            padding: $size-gutter .75em;
        }

        form     { float: right; }
        fieldset { margin: 0; }
        input    { font-size: 1em; padding: ($size-gutter/2); }
    }
}
