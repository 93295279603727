// Declare base placeholder
.miniGallery {
    @include clearfix();
    &-col {
        img {
            width: 100%;
        }
    }
    @include mediumViewport {
        &-col {
            box-sizing: border-box;
            float: left;
            &:nth-last-of-type(3):first-child,
            &:nth-last-of-type(3):first-child ~ .miniGallery-col {
                width: 33%;
            }
        }
        + .text-description {
            ul {
                -webkit-column-count: 2;
                   -moz-column-count: 2;
                        column-count: 2;
                -webkit-column-gap: 4em;
                   -moz-column-gap: 4em;
                        column-gap: 4em;
                > li {
                    -webkit-column-break-inside: avoid;
                              page-break-inside: avoid;
                                   break-inside: avoid;
                }
            }
        }
    }
}
