body {
    color: $color-text-default;
    font-family: $font-family-default;
    font-size:$font-size-normal;
}
h1, h2, h3, h4, h5, h6 {
    color: $color-heading;
    font-family: $font-family-heading;
    font-size: $font-size-normal;
    font-weight:700;
}
h1 { font-size: $font-size-biggest; margin:0 0 .5em; }
h2 { font-size: $font-size-bigger;  margin:$size-gutter 0; }
h3 { font-size: $font-size-big;     margin:$size-gutter 0 ($size-gutter/2) 0; }
h4 { font-size: $font-size-big;     margin:0; }
h5 {                                margin:0; }
h6 {                                margin:0; }
a {
    color: inherit;
    text-decoration:none;
}
p {
    line-height: (20em/16);
    font-size: $font-size-normal;
    font-weight:400;
    margin: $size-gutter 0;

    a { color: $color-link; }
}
address { white-space: pre-line; }
ol, ul { margin:0; padding:0; list-style-type:none; }

table { margin: 1em 0 1em 0; }
th { font-weight: $font-weight-heading; }
td { padding: ($size-gutter/2); }
thead th { text-align:left; }
tbody th { text-align:right; }

fieldset {
    margin: 0 0 3em 0;
    padding:0;
}
form {
    margin: 0 0 .2rem 0;
}
legend {
    color: $color-heading;
    display: block;
    font-size: $font-size-small;
    font-weight: 300;
    margin: 0;
    padding: 0 0 0 0;
}
label { font-weight:normal; }
@media all and (min-width:$viewport-medium) {
    textarea { width:55ex; height:12em; }
}

@media print {
    :root { font-size:10pt; }

    h1 {
        font-size: $font-size-biggest;
        margin: $size-gutter 0 0 0;
    }
    h2 {
        font-size: $font-size-bigger;
        margin: $size-gutter 0 0 0;
    }
}
