body > header {

    background-color: $color-content-background;
    color: $color-header;
    padding-top:$size-gutter; padding-left:$size-gutter;

    a {
        color: $color-header;
        font-family: $font-family-nav;
    }

    > .container {
        h1, h2 {
            display:inline-block;
            margin: .2em 0;
            vertical-align:middle;
        }
        h1 a {
            margin-left: $size-gutter;
            font-size:$font-size-bigger; font-weight:normal;

            @if $url-logo {
                display:inline-block;
                background:url($url-logo) no-repeat left center;
                background-size:2.5rem;
                padding-left:3rem;
                line-height:2.5rem;
            }
        }
        h2 {
            font-size:$font-size-small;
            margin: 0 0 0 2em;
            padding:0 0 0 2em;
            border-left:1px solid $color-border-table;
        }
    }

    .utilityBar {
        position:absolute; right:10px; top:10px;

        nav {
            float: right;
            margin: 0 0 0 1em;
        }
    }
}
