/**
 * Urls are relative to screen.css
 */
$url-logo:              "";
$url-header-background: "";

//Typefaces
@import 'https://fonts.googleapis.com/css?family=Lora';
@import 'https://fonts.googleapis.com/css?family=Roboto';
$serif: "Lora", serif;
$sans:  "Roboto", sans-serif;

$font-family-default: $sans;
$font-family-heading: $serif;
$font-family-nav:     $sans;
$font-weight-heading: 700;

//Font Sizes
$font-size-huge:    2.8125rem;
$font-size-biggest: 1.6667rem;
$font-size-bigger:  1.4000rem;
$font-size-big:     1.2500rem;
$font-size-normal:  1.1250rem;
$font-size-small:   1.0000rem;
$font-size-smaller: 0.8750rem;

// Families

@import 'color-palette';
// Foreground colors
$color-text-default:       $mine-shaft;
$color-text-secondary:     $silver-chalice;
$color-link:               $biscay;
$color-heading:            $mine-shaft;
$color-divider:            $silver;

// Background colors
$color-content-background:  $white;
$color-selected-background: $havelock-blue;

$color-nav1:                $biscay;
$color-header:              $biscay;
$color-header-border:       $azure;
$color-footer:              $dark-slate;
$color-error:               $sunglo;
$color-alert:               $california;
$color-action:              $fern;
$color-action-hover:        $deyork;
$color-accent:              $red;

// Special widget colors
$color-background-field:           $sail;
$color-background-field-highlight: $gin-fizz;
$color-border-input:               $silver-chalice;
$color-border-input-highlight:     $mine-shaft;
$color-border-table:               $wafer;

$color-table-background:           $gainsboro;
$color-table-background-alt:       $silver;
$color-table-header-background:    $biscay;

$color-shadow: rgba(0, 0, 0, 0.5);


$viewport-small:   (350px);
$viewport-medium:  (640px);
$viewport-large:  (1080px);

$size-gutter: .5em;

// Breakpoint mixins
@mixin smallViewport {
    @media all and (min-width: #{$viewport-small}) {
        @content;
    }
}
@mixin mediumViewport {
    @media all and (min-width: $viewport-medium) {
        @content;
    }
}
@mixin largeViewport {
    @media all and (min-width: $viewport-large) {
        @content;
    }
}

@mixin visuallyHidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin clearfix {
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}
