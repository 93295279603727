.dropdown {
    display: inline-block;
    margin: 0 $size-gutter;
    position: relative;

    .launcher {
        border:1px solid $color-border-input;
        border-radius: $size-gutter;
        outline: none;
        background-repeat: no-repeat;
        background-position: $size-gutter center;
        background-size: ($size-gutter*2) ($size-gutter*2);
        background-image:url('../img/fa-svg/gray/svg/chevron-down.svg'); padding-left:1.7em;

        &[aria-expanded="true"] + .links {
            display:block;
        }
        &[aria-expanded="false"] + .links {
            display:none;
        }
    }

    .links {
        background: $color-content-background;
        box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.5);
        min-width: 12rem;
        padding: $size-gutter 0;
        position: absolute;
            top: 100%;
            left: 0;
        text-align: left; // In case a menu gets embedded in a parent that is right-aligned
        transition: opacity .125s ease-in-out;
        z-index: 20;
    }
    .subgroup {
        &:not(:first-of-type):before {
            background-color: $color-link;
            color: $color-content-background;
            content: '';
            display: block;
            height: 1px;
            margin: $size-gutter;
        }
    }
    a {
        font-family: $font-family-heading;
        display: block;
        padding: ($size-gutter/2) $size-gutter;
        &:hover {
            background-color: $color-link;
            color: $color-content-background;
        }
    }
}
