.pricingTable {
    @include clearfix();
    margin-right: auto;
    margin-left: auto;
}
.pricingOffer {
    box-sizing: border-box;
    margin-bottom: 2em;
    &-title {
        font-size: (18rem/16);
        margin-bottom: 0;
    }
    &-meta {
        margin: 0 0 .25em 0;
    }
    &-details {
        > p {
            line-height: 1.25em;
            margin: .33em 0;
        }
    }
    &-value {
        font-size: 2em;
    }
    @include mediumViewport {
        float: left;
        width: 50%;
        &:nth-last-of-type(3):first-child,
        &:nth-last-of-type(3):first-child ~ .pricingOffer {
            width: 33%;
        }
        &-title {
            font-size: (24rem/16);
        }
    }
    @include largeViewport {
        &:nth-last-of-type(4):first-child,
        &:nth-last-of-type(4):first-child ~ .pricingOffer {
            width: 25%;
        }
    }
}
