/**
 * @see https://en.wikipedia.org/wiki/Web_colors
 *
 */


// Blues
$biscay:           #183365;
$azure:            #315da6;
$sail:             #c9e5f7;
$light-blue:       #a1b5d6;
$havelock-blue:    #4990e2;
$dodger-blue:      #3f9dff;
$cornflower:       #90c2e7;

$bright-turquoise: #0ffae3;

// Greens
$fern:             #4caf50;
$sushi:            #8bc34a;
$deyork:           #77cc7d;
$spring-rain:      #a1cca5;

// Browns
$roman-coffee:     #795548;

// Oranges
$california:       #ff9800;
$golden-tainoi:    #ffc95c;
$pomegranate:      #f34213;

// Reds
$red:              #a31621;

// Pinks
$sunglo:           #ed9a9c;
$brink-pink:       #f7567c;

// Greys
$gainsboro:        gainsboro;
$silver:           silver;
$silver-chalice:   #9e9e9e;
$gothic:           #607d8b;
$dark-slate:       #4a4a4a;
$mine-shaft:       #212121;

// Whites
$white:            #fff;
$gin-fizz:         #fffae3;
$vista-white:      #fcf7f8;
$wafer:            #eee4e1;
