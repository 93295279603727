main header {
    h1 {
        display: inline-block;
        font-size: $font-size-bigger;
        margin-right: 1em;
    }

    .tools {
        display: inline-block;
    }
}

section, article {
    margin: 0 0 2em 0;

    section, article { margin:0 0 1rem 0; }
}
