input[type="tel"] { padding-left: 44px; }
input.date, input.time { width:10em; }
input[type="checkbox"], input[type="radio"] {
    font-size: 1em;
    padding: 0;
    vertical-align: middle;
    width:1em;
    height:1em;
}

dl {
    border-top: 1px solid $color-border-table;
    display: block;
    margin: 0;
    padding: $size-gutter 0;
    overflow:auto;

    dt {
        font-family: $font-family-heading;
        font-size: $font-size-normal;
        font-weight:bold;
        margin: $size-gutter*2 0 0 0;
        padding: .2em 0 0 0;
    }
    dd {
        margin: 0;
        padding: 0;

        .help { display:inline-block; margin-left:($size-gutter*2); }
    }
}

form dl {
    border-top: none;

    input:not([type="checkbox"][type="radio"]) {
        box-sizing: border-box;
        border: 1px solid $color-border-input;
        font-family: $font-family-default;
        font-size: $font-size-normal;
        padding: $size-gutter;
        width: 100%;

        &:focus {
            background-color: $color-content-background;
            border-color: $color-border-input-highlight;
        }
    }

    &.checkbox, &.radio {
        label {
            display:block;
            margin: 0;
            vertical-align: middle;
            margin-bottom: ($size-gutter/2);
            padding: 0 0 0 1.6em;
            vertical-align: middle;
            line-height: 1.2em;
        }
    }
    &.select   { }
    &.textarea { }


    @media all and (min-width: $viewport-medium) {
        background: $color-background-field;
        margin: 2px 0;

        &:after {
            clear: both;
            content: '';
            display: table;
        }
        dt {
            color: $color-heading;
            float: left;
            font-size: (13em/16);
            margin: 0;
            padding: .75em 0 0 1em;
            width: 8em;
        }
        dd {
            margin-left: 8em;

        }
        input:not([type="checkbox"][type="radio"]) {
            font-size: (14em/16);
            width: 20em;

            &.datePicker { width:inherit; }
            &.timePicker { width:inherit; }
        }
        &.radio, &.checkbox, &.select {
            dt {
                padding-top: $size-gutter;
            }
        }
    }
}
